import React, { useEffect, useState } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'

import { AuthContext } from './views/shared/context/auth-context'
import { useAuth } from './views/shared/hooks/auth-hook'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// class App extends Component {
const App = () => {
  const { token, login, logout, userId } = useAuth()
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  useEffect(() => {
    if (!token) {
      const timer = setTimeout(() => {
        setRedirectToLogin(true)
      }, 1000) // Delay in milliseconds before redirecting

      return () => clearTimeout(timer)
    }
  }, [token])

  let routes

  if (token) {
    routes = (
      <Routes>
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route exact path="/login" name="Lo in" element={<Navigate to="/dashboard" replace />} />
        {/* <Route path="*" name="Home" element={<DefaultLayout />} /> */}
        <Route
          path="*"
          name="Home"
          element={
            <>
              <DefaultLayout />
              {/* <Navigate to="/dashboard" replace /> */}
            </>
          }
        />
      </Routes>
    )
  } else {
    routes = (
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        {redirectToLogin && <Route path="*" element={<Navigate to="/login" replace />} />}
      </Routes>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <HashRouter>
        <React.Suspense fallback={loading}>{routes}</React.Suspense>
      </HashRouter>
    </AuthContext.Provider>
  )
}

export default App
